.app {
    display: grid;
    /* 1st row(header) is 64px. 2nd row is 100% of display size */
    grid-template-rows: 64px 100vh;
    grid-template-columns: 280px calc(100% - 280px);
}
/* 横幅が 1920px 以下であれば */
@media (max-width: 1920px){
    .app {
        grid-template-columns: 280px calc(1920px - 280px);
    }
}
/* 高さが 1080px 以下であれば */
@media (max-height: 1080px){
    .app {
        grid-template-rows: 64px calc(1080px - 64px);
    }
}

.app__header {
    grid-row: 1;
    grid-column: 1 / 3;
    background-color: #0062C2;
}

.app__sidebar {
    grid-row: 2;
    grid-column: 1;
    background-color: #333941;
}

.app__body {
    grid-row: 2;
    grid-column: 2;
    height: calc(1080px - 64px);
    overflow-y: auto;
}